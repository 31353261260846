.announcement-carousel{
  .carousel-caption{
    position: inherit;
    bottom:inherit;
    right: 0%;
    left: 0%;
    top: -15px;
    text-align: left;
  }
  .carousel-caption p{
    color: #000;
    font-size:1.1em;
  }
  .carousel-caption h3{
    font-size:1.3em
  }
  .carousel-control-prev-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ccc' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
  }
  .carousel-control-next-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ccc' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
  }
  .carousel-control-prev,
  .carousel-control-next{
    top:-50%;
    display: none
  }
  .carousel-control-prev{
    justify-content:left;
    left:-1%
  }
  .carousel-control-next{
    justify-content:flex-end;
    right:-1%
  }
  .carousel-indicators{
    bottom:20%;
    position: inherit;
    float:right;
    margin-top: -25px;
    margin-right: 0
  }
  .carousel-indicators-hide{
    opacity: 0
  }
  .carousel-indicators li {
    background-color: #ccc;
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
  .carousel-indicators li.active {
    background-color: #f39432;
  }
}

@media (max-width: 767.98px) {
  .announcement-carousel{
    z-index: 0;
    .carousel-control-prev{
      left:-3%
    }
    .carousel-control-next{
      right:-3%
    }
  }
}

.mobile-menu {
  background-color: #fff;
  box-shadow: 0 -0.05rem 0.01rem rgba(75, 75, 90, 0.075);
}

.mobile-nav {
  display: flex;
  width : 100%;
  flex-wrap: nowrap;
  list-style: none;
  margin-bottom: 0px;
  padding: 2px 0px;
}

.mobile-nav-item {
  flex: 1 1 auto;
  min-height: 30px;
}

.mobile-nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #4B4B5A;
}

.mobile-nav-link:hover, 
.nav-active > .mobile-nav-link {
  color: #2f7514;
}

.mobile-nav-link i {
  font-size: 18px;
  line-height: 18px;
}

.mobile-nav-link svg {
  height: 22px;
  width: auto;
}

.mobile-nav-link span {
  font-size: 12px;
}

.youtube-video-overlay {
  z-index: 10000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-thumbnail {
  cursor: pointer;
  border-radius: 5px;
  max-width: 360px;
  min-width: 240px;
}

.video-detail {
  width: 100%;
}

.video-title {
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}

.video-thumbnail img {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
}

.video-load-more {
  width: 100%;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
  .video-thumbnail {
    min-width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  .video-detail {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding: 0px 15px;
  }

  .video-thumbnail img {
    border-radius: 0px;
  }

  .video-title {
    font-size: 14px;
    font-weight: bold;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 

}