// 
// horizontal-nav.scss
//

body[data-layout="topnav"] {
    .container-fluid {
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
    }

    .navbar-custom {
        position: relative;
        padding: 0px;
        .topnav-menu {
            .nav-link{
                &:hover,
                &:focus,
                &:active {
                    svg {
                        color: $menu-item;
                    }
                }
            }
        }
    }

    .content-page {
        margin-top: 0px;
        margin-left: 0px !important;
        padding: 05px 0px 5px 0;
    }

    .footer {
        left: 0;
    }

    .user-profile{
        padding: 20px 0px;
    }

    @media (max-width: 1024px)  {
        .button-menu-mobile.disable-btn {
            display: none;
        }
    }

    @media (max-width: 375px)  {
        .button-menu-mobile.disable-btn {
            display: block;
        }
    }
}


.topnav {
    background: $bg-topnav;
}

@include media-breakpoint-up(lg) {
    body[data-layout="topnav"] {
        .container-fluid {
            max-width: 85%;
        }

        .navbar-custom {
            .logo{
                display: block;
                span{
                    &.logo-sm{
                        display: none !important;
                    }
                    &.logo-lg{
                        display: block;
                    }
                }
            }
        }
        &.left-side-menu-condensed {
            .navbar-custom {
                .button-menu-mobile.disable-btn{
                    display: none !important;
                }
            }
        }

        &.boxed-layout {
            .container-fluid {
                max-width: 97%;
            }
        }
    }
    .navbar-toggle {
        display: none;
    }
}

@include media-breakpoint-down(md) {
    body[data-layout=topnav]{
        .navbar-custom{
            .container-fluid{
                padding-right: 12px;
                padding-left: 12px;
            }
        }
    }
    .topnav {
        overflow: auto;
    }
}
