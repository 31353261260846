.player-control {
  margin: 15px 0;
  padding: 10px;
  background-color: #6c757d;
  display: flex;
  align-items: center;

  .button-container {
    width: 50px;
  }

  .horizontal-slider {
    width: 100%;
    height: 6px;
    margin: 0 10px;
    background-color: #f3f4f7;
    display: flex;
    border-radius: 3px;

    .horizontal-slider-thumb {
      width: 20px;
      height: 20px;
      background-color: white;
      top: -7px;
    }
    .horizontal-circle-slider-thumb {
      width: 20px;
      height: 20px;
      background-color:#F5831A;
      top: -7px;
      border-radius: 20px;
      cursor: grab;
    }
    .slider-track {
      height: 6px;
    }
    .slider-track-0 {
      background-color: #F5831A;
    } 
    .slider-track-1 {
      background-color: #dddddd;
    } 
  }

  .duration-container {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: white;
      margin: 0;
    }
  }
}