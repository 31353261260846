.video-player {
  display: flex;
  justify-content: center;
  .youtube-video-container {
    height: auto;
    width: 1200px;
    display: block;

    @media only screen and (min-width: 992px){
      width: 65%;
    }
    
    .youtube-video {
      position: relative;
      padding-top: 25px;
      padding-bottom: 56.25%;
      height: 0;

      > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      .youtube-video-overlay, .youtube-video-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .youtube-video-background {
        z-index: 0;
        background-color: #232323;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .youtube-video-overlay {
        color: transparent;
        z-index: 2;

      }
    }
  }
}
